<template>
  <div class="dm-total-box flex-column-start-center">
    <div class="dm-top-box flex-row-start-between">
      <div class="dm-title-box">
        <!--        <div style="height:38px;flex-grow: 0;flex-shrink: 1;"></div>-->
        <div class="dm-title-main">{{ texts.titleMain }}</div>
        <div class="dm-title-desc">{{ texts.titleDesc }}</div>
      </div>
      <!--      <div class="dm-top-img">-->
      <img class="dm-top-img" :src="getAbsUrl(texts.topImgUrl)" />
    </div>
    <div class="dm-deliver"></div> <!-- 分隔符-->
    <!-- 0-选择视频；1-上传视频 -->
    <div v-show="state<=1" class="dm-bottom-box flex-row-start-start">
      <div class="dm-lb-box">
        <div style="width:100%;height: 154px;" class="flex-column-start-center">
          <div class="dm-sub-title" style="align-self: flex-start;">{{ texts.lbSubTitle }}</div>
          <template v-if="state===0">
            <div class="dm-file-btn" @click="$refs.fileSelector.click()">
              <icon-upload></icon-upload>
              <span class="dm-file-btn-name">{{ texts.lbFileBtnTitle }}</span>
              <input type="file" hidden @change="onSelectFile" ref="fileSelector" />
            </div>
            <div class="dm-file-btn-desc">{{ texts.lbFileBtnDesc }}</div>
          </template>
          <template v-if="state===1">
            <div style="margin: 33px 0 0 16px;align-self: flex-start;">
              <icon-file-video></icon-file-video>
              <span class="dm-file-name">{{ videoFile ? videoFile.name : "" }}</span>
            </div>
            <div style="width:100%;padding:12px 16px 0 16px;">
              <a-progress :percent="uploadPercent" :stroke-width="8" color="#F97846" track-color="#E5E6EB">
                <template v-slot:text="scope">
                  {{ (scope.percent * 100).toFixed(2) }}%
                </template>
              </a-progress>
            </div>
          </template>
        </div>
        <div class="dm-lb-deliver"></div>
        <div style="width:100%;" class="flex-column-start-start">
          <div class="dm-sub-title" style="align-self: flex-start;">{{ texts.lbSubTitle2 }}</div>
        </div>
        <div class="dm-skills-box flex-row-start-start">
          <template v-for="(skill,index) in texts.skills" :key="index">
            <div v-show="index!==0" class="dm-deliver"></div> <!-- 分隔符-->
            <div class="dm-skill-box">
              <img class="dm-skill-img" :src="getAbsUrl(skill.imgUrl)" />
              <div class="dm-skill-title">{{ skill.title }}</div>
              <div class="dm-skill-desc">{{ skill.desc }}</div>
            </div>
          </template>
        </div>
        <div class="dm-lb-deliver"></div>
        <div class="dm-recognize-box flex-row-start-between">
          <div></div>
          <div class="dm-recognize-desc" v-html="texts.lbRecognizeDesc"></div>
          <!--          <div class="dm-recognize-img"></div>-->
          <img class="dm-recognize-img" :src="getAbsUrl(texts.bottomImgUrl)" />
        </div>
      </div>
      <div class="dm-deliver"></div> <!-- 分隔符-->
      <div class="dm-rb-box" v-show="state<=1">
        <div class="dm-sub-title">{{ texts.rbSubTitle }}</div>
        <div class="dm-rb-desc" v-html="texts.rbDesc"></div>
      </div>
    </div>
    <!-- 2-分析视频 -->
    <div v-show="state===2" class="dm-bottom-box flex-row-start-start">
      <div class="dm-lb-box flex-column-start-start" ref="videoBox">
        <div class="dm-sub-title" style="align-self: flex-start;">{{ texts.lbSubTitle }}</div>
        <div class="dm-player-box">
          <video class="dm-player" ref="videoPlayer" :src="videoDataUrl" width="729"></video>
          <!-- 进度条背景 begin -->
          <div class="dm-player-progress-box" v-show="resultType==='Recognition'">
            <!-- 进度条 begin -->
            <div class="dm-player-progress flex-row-start-center" :style="{'width':playProgress+'%'}">
              <!-- 进度条中的对象识别结果标记 begin -->
              <a-popover v-for="(item) in sortedImageResults" :key="item.id"
                         :content-style="{padding:0,margin:0}"
                         trigger="click" @popup-visible-change="popup($event,item.record)">
                <div class="dm-player-flag" :style="item.style"></div>
                <template #content>
                  <div class="float-box">
                    <img class="float-img" :src="item.record.imageUrl" :id="`flag-img-${item.id}`"
                         @load="boxImgLoad(`flag-img-${item.id}`,item.record,174)" />
                    <div class="flex-row-start-center float-icon-box">
                      <template v-for="(obj) in item.record.objects" :key="obj.boxId">
                        <img class="float-icon" :src="getObjCategoryIconUrl(obj.category)" />
                      </template>
                    </div>
                  </div>
                </template>
              </a-popover>
              <!-- 进度条中的对象识别结果标记 end -->
            </div>
            <!-- 进度条 end -->
          </div>
          <!-- 进度条背景 end -->
          <!-- event进度条背景 begin -->
          <div class="dm-player-progress-box" v-show="resultType==='Event'"
               v-for="(item) in reverseEventResults" :key="item.id">
            <div v-for="(timeRange,index) in item.record.timeRanges"
                 class="event-time-range" :style="item.timeRangesStyles[index]">
            </div>
          </div>
          <!--  event进度条背景 end -->
        </div>
        <div class="dm-player-btn-box  flex-row-start-center">
          <div class="dm-player-btn dm-player-btn-cancel" @click="reUpload">Re-Upload</div>
          <div class="dm-player-btn dm-player-btn-confirm" @click="onAnalyze">Analyze</div>
        </div>
      </div>
      <div class="dm-deliver"></div> <!-- 分隔符-->
      <div class="dm-rb-box">
        <div class="dm-sub-title">{{ texts.rbSubTitle }}</div>
        <div class="flex-row-around-center"><span></span>
          <span class="show-all-details" @click="showAllDetails">show all details</span>
        </div>
        <a-tabs v-model="resultType" :header-padding="true" :animation="true">
          <a-tab-pane :key="'Recognition'">
            <template #title>
              <span class="my-tab-pane-title">Recognition</span>
            </template>
            <div class="results-box">
              <template v-for="(item) in reverseImageResults" :key="item.id">
                <div class="result-box flex-row-start-start">
                  <div class="flex-column-start-start">
                    <img class="result-img" :src="item.record.imageUrl" :id="`msg-img-${item.id}`"
                         @load="boxImgLoad(`msg-img-${item.id}`,item.record,197)" />
                  </div>
                  <div class="result-right">
                    <div class="result-time flex-row-around-center">
                      <span>{{ millsToTime(item.record.timeInVideo) }}</span>
                      <span class="result-open-btn" @click="item.showDetail=!item.showDetail">
                        <icon-down v-show="!item.showDetail"></icon-down>
                        <icon-up v-show="item.showDetail"></icon-up>
                      </span>
                    </div>
                    <div class="result-icon-box">
                      <template v-for="obj in item.record.objects">
                        <img class="result-icon" :src="getObjCategoryIconUrl(obj.category)" />
                      </template>
                    </div>
                  </div>
                </div>
                <div class="result-detail" v-show="item.showDetail">
                  <pre>{{ toPrettyJson(item.record) }}</pre>
                </div>
              </template>
            </div>
          </a-tab-pane>
          <a-tab-pane :key="'Event'">
            <template #title>
              <span class="my-tab-pane-title">Event</span>
            </template>
            <div class="results-box">
              <template v-for="(item) in reverseEventResults" :key="item.id">
                <div class="result-box flex-row-start-start">
                  <img class="result-img" :src="item.record.summaryUrl" />
                  <div class="result-right">
                    <div class="result-time flex-row-around-center">
                      <span style="font-size: 14px;">{{ getEventName(item.record.name) }}</span>
                      <span class="result-open-btn" @click="item.showDetail=!item.showDetail">
                        <icon-down v-show="!item.showDetail"></icon-down>
                        <icon-up v-show="item.showDetail"></icon-up>
                      </span>
                    </div>
                    <div class="result-icon-box">
                      <!--                    <template v-for="obj in item.record.objects">-->
                      <img class="result-icon" :src="getObjCategoryIconUrl(item.record.objectCategory)" />
                    </div>
                  </div>
                </div>
                <div class="result-detail" v-show="item.showDetail">
                  <pre>{{ toPrettyJson(item.record) }}</pre>
                </div>
              </template>
            </div>
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
    <!-- 3-视频结果详情 -->
    <div v-show="state===3" class="detail-box flex-column-start-start">
      <div class="detail-header">
            <span class="detail-back" @click="state=2">
              <icon-left :size="20"></icon-left>
            </span>
        <span>Details</span>
      </div>
      <div class="detail-content flex-column-start-center">
        <div v-for="(item) in reverseImageResults" :key="item.id"
             class="detail-item flex-row-start-start">
          <div class="detail-left flex-column-start-start">
            <img class="detail-img" :src="item.record.imageUrl" :id="`detail-img-${item.id}`"
                 @load="boxImgLoad(`detail-img-${item.id}`,item.record,729)" />
            <div class="detail-text" style="margin-top: 16px;">{{ millsToTime(item.record.timeInVideo) }}</div>
            <div class="detail-text" style="margin-top: 6px;">{{ getImgDesc(item.record) }}</div>
            <div class="result-icon-box" style="margin-top: 6px;">
              <template v-for="obj in item.record.objects">
                <img class="result-icon" :src="getObjCategoryIconUrl(obj.category)" />
              </template>
            </div>
          </div>
          <div class="detail-right">
            <pre>{{ toPrettyJson(item.record) }}</pre>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import request from "@/utils/request";
import SockJS from "sockjs-client";
import { v4 as uuidv4 } from "uuid";
import { IconFileVideo, IconUpload, IconDown, IconUp, IconLeft } from "@arco-design/web-vue/es/icon";

// 文本常量
const texts = {
  titleMain: "Reveal A4X AI Power",
  titleDesc: "Uni-Smart Demo is a showcase for A4x AI skills that make it easier for anyone to start exploring A4x AI power, through uploading any video you want to check, you will get the AI result in real-time.",
  lbSubTitle: "Upload and analyze",
  lbFileBtnTitle: "Select file",
  lbFileBtnDesc: "Upload a video less than 100 MB in MP4 format.",
  lbSubTitle2: "Available AI skills",
  rbSubTitle: "Analysis result",
  topImgUrl: "ai-demo/top.png",
  bottomImgUrl: "ai-demo/bottom.png",
  skills: [
    {
      imgUrl: "ai-demo/skill_1.png",
      title: "Pet detection",
      desc: "Detect whether there are pets (cats, dogs) in the video. And get the pet's location"
    },
    {
      imgUrl: "ai-demo/skill_2.png",
      title: "Package detection",
      desc: "Detect whether there are pets (cats, dogs) in the video. And get the pet's location"
    },
    {
      imgUrl: "ai-demo/skill_3.png",
      title: "Vehicle detection",
      desc: "Detect whether there are pets (cats, dogs) in the video. And get the pet's location"
    }
  ],
  lbRecognizeDesc: "" +
    "<span>Please install the camera at a height of approximately 2.5m and adjust it to a downward looking angle.</span><br/>" +
    "<span>Make sure that the package is clearly visible on the view and not obscured by other objects.</span><br/>" +
    "<span>The video that meets the above conditions is easier to detect the package.</span><br/>" +
    "<br/>" +
    "<span>Tip: Packages that are too large or too small may affect the detection results.</span>",
  rbDesc: "" +
    "<span>1. Upload the video to be analyzed</span><br/>" +
    "<span>2. Play the video</span><br/>" +
    "<span>3. If there is a analyze result, it will be shown here</span>"

};
const accessList = [
  // { accountId: "dogcare", key: "Ix2pxQI3QhOKx1YYe5CUT" },
  { accountId: "uni_smart_demo", key: "NEYXF6gnmgxQ5Nkuc8xWd5" },
  { accountId: "paastest", key: "viVqluNTSOF50sly757fF7" }
];

const objCategoryIconUrls = {
  "PERSON": "ai-demo/obj_category_person@2x.png",
  "PET": "ai-demo/obj_category_pet@2x.png",
  "VEHICLE": "ai-demo/obj_category_vehicle@2x.png",
  "PACKAGE": "ai-demo/obj_category_package@2x.png",
  "BIRD": "ai-demo/obj_category_bird@2x.png",
  "OTHER": "ai-demo/obj_category_other@2x.png"
};

const objCategoryFlagColors = {
  "PERSON": "#D8A65C",
  "PET": "#54C6F7",
  "VEHICLE": "#8276E7",
  "PACKAGE": "#FCA26E",
  "BIRD": "#71E3AE",
  "OTHER": "#F97846"
};

export default {
  components: {
    IconFileVideo, IconUpload, IconDown, IconUp, IconLeft
  },
  data() {
    return {
      state: 0, // 0-未选择文件，2-选择文件后正在上传
      playProgress: 0,

      maxVideoFileSize: 100, // MB
      videoBoxHeight: 0,
      // deployLevels: (process.env.NODE_ENV === 'production') ? ['staging', 'prod'] : ['staging'],
      // deployLevels: ['staging'],
      // deployLevels: ['prod', 'staging'],

      consoleNodes: [],
      consoleNode: null,
      showNodes: false,

      saasNodes: [],
      saasNode: null,

      accessList: accessList,
      access: accessList[0],

      uploadPercent: 0,
      socket: null,
      loading: false,
      videoFile: null,
      /* ai任务选项 */
      // tenantId: 'netvue', // todo
      // sn: 'aiDemo_test_sn', // todo
      // tenantId: 'aiDemo_test_tenant', // todo
      // sn: 'sn_prod_us', // todo
      tenantId: process.env.VUE_APP_UNI_SMART_DEMO_TENANT_ID,
      // sn: null,
      imagesPerSecond: 2,
      imagesPerSendTask: 4,
      eventOptions: [
        {
          eventObject: "person", checked: true, name: "人"
          , category: "PERSON", functions: ["RECOGNITION", "EVENT"]
          , color: "", isDrawBox: true /*是否需要算法端画框*/
        },
        {
          eventObject: "pet", checked: true, name: "宠物"
          , category: "PET", functions: ["RECOGNITION", "EVENT"]
          , color: "", isDrawBox: true /*是否需要算法端画框*/
        },
        {
          eventObject: "vehicle", checked: true, name: "车辆"
          , category: "VEHICLE", functions: ["RECOGNITION", "EVENT", "ID"]
          , color: "", isDrawBox: true /*是否需要算法端画框*/
        },
        {
          eventObject: "package", checked: true, name: "包裹"
          , category: "PACKAGE", functions: ["RECOGNITION", "EVENT"]
          , color: "", isDrawBox: true /*是否需要算法端画框*/
        },
        {
          eventObject: "bird", checked: true, name: "鸟类"
          , category: "BIRD", functions: ["RECOGNITION", "EVENT"]
          , color: "", isDrawBox: true /*是否需要算法端画框*/
        },
      ],
      /* 上传视频结果 */
      videoDataUrl: null,
      images: null, // []
      traceId: null,
      /* 变量 */
      imageIndex: 0,
      playTime: 0,
      imageSendOrder: [],
      /* ai分析结果 */
      aiTaskResults: [],
      /* 对象识别结果
      {
			"imageUrl": "https://addx-staging.s3.cn-north-1.amazonaws.com.cn/ai-saas-out-storage/65457138-a15d-42d7-a0c1-f52fc5f45569_notify_recognitionlBanuJHcmpInjO1sk2ZYb_9_36.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20220127T074037Z&X-Amz-SignedHeaders=host&X-Amz-Expires=1800&X-Amz-Credential=AKIAWLWALEBSKSYJSEHR%2F20220127%2Fcn-north-1%2Fs3%2Faws4_request&X-Amz-Signature=49d9020845a7ffd4cc4fa492564ea85543376125dc9ebd14cecf57ddcf2d6630",
			"objects": [{
				"confidence": "86.110672",
				"id": {
					"labelId": "",
					"enable": false
				},
				"position": {
					"top": "0.175000",
					"left": "0.521094",
					"width": "0.149219",
					"height": "0.323611"
				},
				"activityZoneIds": [],
				"category": "PERSON",
				"boxId": "90000"
			}]
      * */
      imageResults: [],
      imageFlagUnit: 10, // 每个标记相对于容器左侧的距离
      /* 事件分析结果 */
      eventResults: [],
      eventFlagUnit: 0, // 每单位时间对应的进度条长度
      resultTypes: [
        { type: "Recognition" },
        { type: "Event" }
      ],
      resultType: "Recognition"
    };
  },
  async created() {
    /** 增加选点逻辑 begin **/
    console.log("uni-smart query=", this.$route.query);
    if (this.$route.query.showNodes) {
      this.showNodes = true;
    }
    try {
      this.consoleNodes = await this.queryNode("console", ["prod", "staging"]);
      this.consoleNode = this.consoleNodes.length > 0 ? this.consoleNodes.filter(it => it.autoSelect)[0] : null;
    } catch (e) {
      console.log("queryNode console error", e);
    }
    try {
      this.saasNodes = await this.queryNode("saas", ["staging"]);
      this.saasNode = this.saasNodes.length > 0 ? this.saasNodes.filter(it => it.autoSelect)[0] : null;
    } catch (e) {
      console.log("queryNode saas error", e);
    }
    if (this.consoleNode === null) {
      this.consoleNode = {
        serviceId: 0,
        nodeName: process.env.VUE_APP_NODE_NAME,
        baseUrl: process.env.VUE_APP_BASE_API
      };
      this.consoleNodes = [this.consoleNode];
    }
    console.log("uni-smart consoleNode=", JSON.stringify(this.consoleNode));
    /** 增加选点逻辑 end **/
  },
  beforeUnmount() {
    if (this.socket) {
      this.socket.close();
    }
  },
  mounted() {
    this.initVideoPlayer();
  },
  methods: {
    initVideoPlayer() {
      this.videoBoxHeight = this.$refs.videoBox.offsetHeight;
      const videoPlayer = this.$refs.videoPlayer;
      videoPlayer.oncanplay = () => {
        console.log("videoPlayer oncanplay!");
        this.videoBoxHeight = this.$refs.videoBox.offsetHeight;
      };
      // 监听播放开始
      videoPlayer.addEventListener("play", () => {
        console.log("videoPlayer play!");
        if (this.playTime === 0) {
          this.traceId = uuidv4();
        }
      });
      // 监听播放结束
      videoPlayer.addEventListener("pause", () => {
        console.log("videoPlayer pause!");
      });
      // 监听播放结束
      videoPlayer.addEventListener("ended", () => {
        console.log("videoPlayer ended!");
        this.sendAiTask(Math.floor(this.playTime * this.imagesPerSecond), this.images.length);
        this.playTime = 0;
      });
      // 使用事件监听方式捕捉事件， 此事件可作为实时监测video 播放状态
      videoPlayer.addEventListener("timeupdate", () => {
        // 用秒数来显示当前播放进度
        const playTime = videoPlayer.currentTime;
        this.playProgress = playTime * 100 / videoPlayer.duration;
        // console.log('videoPlayer timeupdate! playTime=', playTime)
        const startIndex = Math.floor(this.playTime * this.imagesPerSecond);
        const endIndex = Math.floor(playTime * this.imagesPerSecond);
        // this.imageIndex = Math.min(endIndex, this.images.length)
        if (playTime < this.playTime) {
          this.playTime = playTime;
          return; // 进度条往回拖动了
        }
        if (endIndex - startIndex < this.imagesPerSendTask) {
          return; // 不足一个发送AI任务的批次
        }
        this.playTime = playTime;
        this.sendAiTask(startIndex, endIndex);
      }, false);
    },
    // 获取图片内容宽高
    getImageWH(img) {
      const imgObj = new Image();
      imgObj.src = img.src;
      return { width: imgObj.width, height: imgObj.height };
    },
    detailImgLoad(refName, record, styles) {
      // let styles = item.detailBoxStyles;
      // if (styles.length > 0) {
      //   styles = item.detailBoxStyles = [];
      // }
      if (styles.length > 0) {
        return;
      }
      // const record = item.record;
      const img = this.$refs[refName];
      // 获取图片元素宽高
      const W = img.offsetWidth, H = img.offsetHeight;
      console.log("detailImgLoad", refName, W, H);
      let preH = 0;
      for (const obj of record.objects) {
        if (!this.frontIsDrawBox(obj.category)) continue;
        /*"position": {
                "top": "0.156944",
                "left": "0.469531",
                "width": "0.175781",
                "height": "0.366667"
            },*/
        const x = parseFloat(obj.position.left) * W;
        const y = (1 - parseFloat(obj.position.top)) * H + preH; // 相对布局的元素依然有高度
        const w = parseFloat(obj.position.width) * W;
        const h = parseFloat(obj.position.height) * H;
        const color = objCategoryFlagColors[obj.category] || objCategoryFlagColors["OTHER"];
        styles.push({
          // marginLet: `${x}px`, marginTop: `-${y}px`,
          left: `${x}px`, top: `-${y}px`,
          width: `${w}px`, height: `${h}px`,
          borderColor: color
          // marginBottom: `${y}px`
        });
        preH += h;
      }
      if (styles.length > 0) {
        // 用负边距，让识别框不占据高度
        styles[styles.length - 1].marginBottom = `-${preH}px`;
        console.log("detailImgLoad styles", styles);
      }
    },
    boxImgLoad: function(id, record, W) {
      const img = document.getElementById(id);
      // const img = this.$refs[refName];
      if (!img || !img.parentNode) {
        console.log("boxImgLoad null ", img, img.parentNode);
        setInterval(() => this.boxImgLoad(id, record, W), 100);
        return;
      }
      // 获取图片元素宽高
      // const W = img.offsetWidth, H = img.offsetHeight;
      const imgObj = new Image();
      imgObj.src = img.src;
      const H = W * imgObj.height / imgObj.width;

      console.log("boxImgLoad", id, W, H, img.style);
      const styles = [];
      const styleTexts = [];
      let preH = 0;
      for (const obj of record.objects) {
        if (!this.frontIsDrawBox(obj.category)) continue;
        /*"position": {
                "top": "0.156944",
                "left": "0.469531",
                "width": "0.175781",
                "height": "0.366667"
            },*/
        const x = parseFloat(obj.position.left) * W;
        const y = (1 - parseFloat(obj.position.top)) * H + preH; // 相对布局的元素依然有高度
        const w = parseFloat(obj.position.width) * W;
        const h = parseFloat(obj.position.height) * H;
        const color = objCategoryFlagColors[obj.category] || objCategoryFlagColors["OTHER"];
        preH += h;
        styles.push({
          // marginLet: `${x}px`, marginTop: `-${y}px`,
          left: `${x}px`, top: `-${y}px`,
          width: `${w}px`, height: `${h}px`,
          borderColor: color,
          // marginBottom: `${y}px`
          border: "2px solid #8276E7", opacity: "1", position: "relative"
        });
        styleTexts.push(`left:${x}px;top:-${y}px;width:${w}px;height:${h}px;` +
          `border:2px solid ${color};opacity:1;position:relative;`);
      }
      if (styles.length > 0) {
        // 用负边距，让识别框不占据高度
        styles[styles.length - 1].marginBottom = `-${preH}px`;
        styleTexts[styleTexts.length - 1] += `margin-bottom:-${preH}px`;
        console.log("boxImgLoad styles", styleTexts);

        const refNode = this.getNextElement(img);
        // console.log("parentNode", img, img.parentNode, refNode);
        for (let styleText of styleTexts) {
          let div = document.createElement("div");
          div.style.cssText = styleText;
          img.parentNode.insertBefore(div, refNode);
        }
      }
    },
    getImgDesc(record) {
      return record.objects.map(it => it.category.toLowerCase()).join(",");
    },
    reUpload() {
      this.state = 0;
      this.resultType = "Recognition";
      this.videoFile = null;
      this.videoDataUrl = null;
      this.images = [];
      this.traceId = null; // 会连带重置"视频播放和分析"相关变量
    },
    showAllDetails() {
      console.log("showAllDetails");
      this.state = 3;
    },
    getEventName(name) {
      return name ? name.toLowerCase().replaceAll("_", " ") : "";
    },
    createSock(baseUrl) {
      console.log("sockjs baseUrl:", baseUrl);
      // const sock = new SockJS(this.buildUrl('/aiDemo/sockjs'))
      const sock = new SockJS(baseUrl + "/aiDemo/sockjs");
      sock.onopen = () => {
        console.log("sock open!");
        // sock.send('test');
      };

      sock.onmessage = (e) => {
        /*
        {
          "type": "AI_RECOGNITION_RESULT",
          "traceId": "xyz789xyz789xyz789xyz789xyz789",
          "deviceSn": "device_dskflsdkls",
          "ownerId": "user_18923ehfjkeh",
          "taskId": "abc123abc123abc123abc123abc123",
          "order": 0,
          "images": [{
              "imageOrder": 0,
              "imageUrl": "http://aaa/bbb/ccc",
              "timeInVideo": 1234567890123,
              "objects": [{
                  "boxId": "box_12238273",
                  "category": "PERSON",
                  "confidence": 82.87031555175781,
                  "activityZoneId": 123455
                  "id": {
                      "enable": true,
                      "labelId": "abc123abc123abc123abc123abc123"
                  },
                  "position": {
                      "left": 0.4676034152507782,
                      "top": 0.1456310749053955,
                      "width": 0.17954722046852113,
                      "height": 0.37447988986968996
                  }
              }]
          }],
       }
       */
        console.log("sock message!", e.data);
        const result = JSON.parse(e.data).data;
        if (result.type === "AI_RECOGNITION_RESULT") {
          for (const item of result.images) {
            this.imageResults.push({ record: item, id: uuidv4() });
          }
        } else if (result.type === "AI_EVENT_RESULT") {
          for (const item of result.events) {
            this.eventResults.push({ record: item, id: uuidv4() });
          }
        }
        console.log("imageResults.length=", this.imageResults.length, "eventResults.length=", this.eventResults.length);
      };

      sock.onclose = () => {
        console.log("sock close!");
      };
      return sock;
    },
    getObjCategoryIconUrl(category) {
      return this.objCategoryIconUrls[category] || this.objCategoryIconUrls["OTHER"];
    },
    millsToTime(t) {
      const ms = t % 1000;
      t = Math.floor(t / 1000);
      const s = t % 60;
      t = Math.floor(s / 60);
      const m = t;
      return this.fixWidth(m + "", 2, "0") + ":" + this.fixWidth(s + "", 2, "0") +
        ":" + this.fixWidth(ms + "", 3, "0");
    },
    fixWidth(str, width, ch) {
      while (str.length < width) {
        str = ch + str;
      }
      return str;
    },
    onAnalyze() {
      const videoPlayer = this.$refs.videoPlayer;
      console.log("videoPlayer:", videoPlayer);
      this.imageFlagUnit = videoPlayer.width / this.images.length;
      this.eventFlagUnit = videoPlayer.width / videoPlayer.duration;
      console.log("imageFlagUnit=", this.imageFlagUnit, "=", videoPlayer.width, "/", this.images.length);
      console.log("eventFlagUnit=", this.eventFlagUnit, "=", videoPlayer.width, "/", videoPlayer.duration);
      videoPlayer.play();
    },
    async onSelectFile(event) {
      const files = event.target.files;
      if (files.length === 0) return;
      const file = files[0];
      console.log("onSelectFile:", file);
      /*
      const dataUrl = await this.readFileToDataUrl(file);
      const data = dataUrl.substr(dataUrl.indexOf(",") + 1);
      this.fileName = file.name;
      */
      if (!file.name.endsWith(".mp4")) {
        this.$message.warning(this.selectVideoFileError);
        return;
      }
      if (file.size > this.maxVideoFileSizeByte) {
        this.$message.warning(this.selectVideoFileError);
        return;
      }
      this.videoFile = file;
      this.state = 1;
      const uploadSuccess = await this.submitUpload();
      if (uploadSuccess) {
        this.state = 2;
      } else {
        this.state = 0;
      }
    },
    async submitUpload() {
      // this.$refs.upload.submit()
      if (!this.videoFile) {
        this.$message.warning("Please select a video file!");
        return false;
      }
      this.uploadPercent = 0;
      console.log("submitUpload! file=", this.videoFile);
      const formData = new FormData();
      // formData.append('clientId', this.clientId)
      formData.append("imagesPerSecond", this.imagesPerSecond + "");
      formData.append("file", this.videoFile);

      const uploadConfig = {
        baseURL: this.consoleNode.baseUrl,
        url: "/aiDemo/upload",
        method: "post",
        headers: { "Authorization": this.$store.getters.token },
        data: formData,
        timeout: 120 * 1000, // 上传比较慢，增加超时时间
        onUploadProgress: progressEvent => {
          this.uploadPercent = (progressEvent.loaded / progressEvent.total) * 0.5;
          console.log(`上传进度：total=${progressEvent.total},loaded=${progressEvent.loaded},uploadPercent=${this.uploadPercent}`);
        },
        onDownloadProgress: downEvent => {
          this.uploadPercent = 0.5 + (downEvent.loaded / downEvent.total) * 0.5;
          console.log(`下载进度：total=${downEvent.total},loaded=${downEvent.loaded},uploadPercent=${this.uploadPercent}`);
        }
      };
      console.log("submitUpload! uploadConfig=", JSON.stringify(uploadConfig));
      try {
        // const response = await axios.request(uploadConfig)
        const response = await request(uploadConfig);
        console.log("submitUpload response:", response);
        const { code, video, images, traceId, imagesPerSecond } = response;
        if (code !== 0) {
          this.$message.warning("Failed to upload video file!");
          return false;
        }
        // 计算抽取出的图片在视频中的毫秒值
        for (let i = 0; i < images.length; i++) {
          images[i].timeInVideo = Math.round(i * 1000 / this.imagesPerSecond);
        }
        this.traceId = traceId;
        this.imagesPerSecond = imagesPerSecond;
        this.images = images;
        if (video != null) {
          this.videoDataUrl = this.videoBase64ToDataUrl(video.encodedString);
        } else {
          this.videoDataUrl = await this.readFileToDataUrl(this.videoFile);
        }
        return true;
      } catch (e) {
        console.log("error:", e);
        this.$message.warning("Failed to upload video file!");
        return false;
      } finally {
        this.loading = false;
      }
    },
    videoBase64ToDataUrl(base64Str) {
      return "data:video/mp4;base64," + base64Str;
    },
    imageBase64ToDataUrl(base64Str) {
      return "data:image/jpg;base64," + base64Str;
    },
    async queryNode(serviceName, deployLevels) {
      const requestConfig = {
        // baseURL: this.consoleNode.baseUrl,
        url: "/cloud/queryNode",
        method: "post",
        data: { serviceName: serviceName, deployLevels: deployLevels }
        // data: {serviceName: serviceName, deployLevels: this.deployLevels, landNo: '*', countryNo: '*'}
      };
      const response = await request(requestConfig);
      console.log("queryNode", serviceName, JSON.stringify(deployLevels), ",response:", JSON.stringify(response, null, 4));
      if (!response || response.code !== 0) {
        throw new Error("查询节点失败!");
      }
      return response.data;
    },
    async updateDeviceConfig(sn) {
      const requestConfig = {
        baseURL: this.consoleNode.baseUrl,
        url: "/aiDemo/sendAiTask",
        method: "post",
        headers: {
          "Authorization": this.$store.getters.token,
          "Content-Type": "application/json"
        },
        params: {
          saasNode: this.saasNode.nodeName,
          accessKey: this.access.key,
          path: "/ai-saas/device-config/update"
        },
        data: {
          tenantId: this.tenantId,
          devices: [{sn: sn, enable: true}]
        }
      };
      console.log("updateDeviceConfig requestConfig=", requestConfig);
      try {
        const response = await request(requestConfig);
        console.log("updateDeviceConfig response:", response);
      } catch (e) {
        console.log("error:", e);
      }
    },
    async sendAiTask(startIndex, endIndex) {
      if (startIndex >= endIndex) return;
      this.imageIndex = endIndex;
      const sendOrder = startIndex === 0 ? 0 : (this.imageSendOrder[startIndex - 1] + 1);
      const imageIsLast = endIndex === this.images.length;
      console.log("sendAiTask begin: startIndex=", startIndex, "endIndex=", endIndex, "sendOrder=", sendOrder, "imageIsLast=", imageIsLast);
      const dataList = [];
      for (let i = startIndex; i < endIndex; i++) {
        dataList.push(this.images[i]);
        this.imageSendOrder[i] = sendOrder;
      }
      const requestConfig = {
        baseURL: this.consoleNode.baseUrl,
        url: "/aiDemo/sendAiTask",
        method: "post",
        headers: {
          "Authorization": this.$store.getters.token,
          "Content-Type": "application/json"
        },
        params: {
          // saasNode: (this.consoleNode.nodeName === 'prod-us' ? 'staging-us' : 'staging-cn')
          saasNode: this.saasNode.nodeName,
          accessKey: this.access.key,
          path: "/ai-saas/process-ai-task/v2" // 新算法
        },
        data: {
          tenantId: this.tenantId,
          // sn: (this.consoleNode.nodeName === 'prod-us' ? 'sn_prod_us' : 'sn_staging_cn'),
          sn: "aiDemo_sn_" + this.traceId,
          traceId: this.traceId,
          /*events: this.events,*/
          order: sendOrder,
          isLast: imageIsLast,
          dataList: dataList,
          inputType: 1, // VIDEO(0), IMAGES(1), IMAGE(2)
          webhookUrl: this.consoleNode.baseUrl + "/aiDemo/aiTaskResultV2",
          // accessKey: process.env.VUE_APP_UNI_SMART_DEMO_ACCESS_KEY,
          // outEncodeType: "base64",
          outEncodeType: "url",
          // 20220124新版算法变化：'/ai-saas/process-ai-task/v2' delete {events} add {ownerId,modelNo,recognitionObjects,idBox}
          ownerId: "aiDemo_uid_" + this.$store.getters.userInfo.userId,
          modelNo: "aiDemo_modelNo",
          recognitionObjects: this.recognitionObjects,
          idBox: this.idBox,
          activityZoneList: [] // 变成了必传字段
        }
      };
      console.log("sendAiTask requestConfig=", requestConfig);
      try {
        // const response = await axios.request(requestConfig)
        const response = await request(requestConfig);
        console.log("sendAiTask response:", response);
        const { code } = response;
        if (code !== 0) {
          // this.$message.warning('Failed to upload video file!')
          return;
        }
        // console.log('videoDataUrl:', this.videoDataUrl)
      } catch (e) {
        console.log("error:", e);
        // this.$message.warning('Failed to upload video file!')
      } finally {
        this.loading = false;
      }
    },
    popup(visible, record) {
      if (visible) {
        console.log("popup!", visible, record);
      }
    },
    frontIsDrawBox(category) {
      for (const it of this.eventOptions) {
        if (it.category === category && !it.isDrawBox) {
          return true;
        }
      }
      return false;
    }
  },
  computed: {
    sortedImageResults() {
      const arr = this.imageResults.slice();
      arr.sort((l, r) => l.record.imageOrder - r.record.imageOrder);
      const width = 4;
      arr.forEach((it, index) => {
        const preImageOrder = index > 0 ? arr[index - 1].record.imageOrder : 0;
        let marginLeft = (it.record.imageOrder - preImageOrder) * this.imageFlagUnit;
        if (index > 0 && preImageOrder === index - 1) {
          marginLeft -= width;
        }
        it.style = { marginLeft: marginLeft + "px", width: width + "px" };
      });
      // console.log("sortedImageResults", arr.map(it => it.imageOrder + " : " + it.style.marginLeft));
      return arr;
    },
    reverseImageResults() {
      const arr = this.imageResults.slice();
      arr.sort((l, r) => r.record.imageOrder - l.record.imageOrder);
      arr.forEach((it, index) => {
        it.showDetail = index === 0; // 默认打开最新一个
        // it.msgBoxStyles = []; // 消息列表 识别框样式
        // it.detailBoxStyles = []; // 详情列表 识别框样式
      });
      return arr;
    },
    reverseEventResults() {
      const arr = this.eventResults.slice();
      arr.reverse(); // 事件按收到的倒序
      arr.forEach((it, index) => {
        it.showDetail = index === 0; // 默认打开最新一个

        const backgroundColor = objCategoryFlagColors[it.objectCategory] || objCategoryFlagColors["OTHER"];
        it.timeRangesStyles = [];
        const ranges = it.record.timeRanges;
        for (const j in ranges) {
          const preEnd = j > 0 ? ranges[j - 1].end : 0;
          const marginLeft = (ranges[j].begin - preEnd) * this.eventFlagUnit;
          const width = (ranges[j].end - ranges[j].begin) * this.eventFlagUnit;

          it.timeRangesStyles.push({ marginLeft, width, backgroundColor });
        }
        console.log("it.timeRangesStyles=", it.timeRangesStyles);
      });
      return arr;
    },
    showResults() {
      return this.reverseImageResults.length > 0 || this.reverseEventResults.length > 0;
    },
    objCategoryIconUrls() {
      return this.getAbsUrls(objCategoryIconUrls);
    },
    texts() {
      return texts;
    },
    maxVideoFileSizeByte() {
      return this.maxVideoFileSize * 1024 * 1024;
    },
    selectVideoFileError() {
      return `Only MP4 allowed (less than ${this.maxVideoFileSize}MB)`;
    },
    events() {
      return this.eventOptions.filter(it => it.checked).map(it => it.eventObject);
    },
    recognitionObjects() {
      return this.eventOptions.filter(it => it.checked).map(it => {
        return { category: it.category, functions: it.functions };
      });
    },
    idBox() {
      // { visualizeRecognition:[''],colors:[{name:'',color:''}]
      // colors 如果没有配置则使用算法默认颜色 eg: {name:'PERSON',color:'#112233'}
      const idBox = { visualizeRecognition: [], colors: [] };
      for (const it of this.eventOptions) {
        if (it.checked && it.isDrawBox) {
          idBox.visualizeRecognition.push(it.category);
          const color = (it.color && it.color != "") ? it.color : objCategoryFlagColors[it.category];
          if (color && color != "") {
            idBox.colors.push({name: it.category, color: color.substring(1)}); // 去掉颜色值开头的#号
          }
        }
      }
      return idBox;
    },
    aiTaskResultsReverse() {
      // return this.aiTaskResults.reverse()
      const len = this.aiTaskResults.length;
      const arr = new Array(len);
      for (let i in this.aiTaskResults) {
        arr[len - i - 1] = this.aiTaskResults[i];
      }
      return arr;
    },
    selectBtnTitle() {
      if (!this.loading) {
        return "Click to select";
      } else {
        return "Uploading...";
      }
    }
  },
  watch: {
    async traceId(newVal, oldVal) {
      console.log("traceId changed!", oldVal, "->", newVal);
      if (newVal !== oldVal) {
        console.log("sock setTraceId on change:", this.traceId);
        this.socket.send(JSON.stringify({ name: "setTraceId", traceId: newVal }));
      }
      // 重置播放状态
      this.imageIndex = 0;
      this.playTime = 0;
      this.imageSendOrder = new Array(this.images.length);
      this.aiTaskResults = [];
      this.imageResults = [];
      this.eventResults = [];

      await this.updateDeviceConfig("aiDemo_sn_" + this.traceId);
    },
    consoleNode(newVal, oldVal) {
      console.log("consoleNode changed!", JSON.stringify(oldVal), "->", JSON.stringify(newVal));
      if (!oldVal || oldVal.baseUrl !== newVal.baseUrl) {
        if (this.socket) {
          try {
            this.socket.close();
          } catch (e) {
            console.log("close socket err!", e);
          }
        }
        this.socket = this.createSock(newVal.baseUrl);
      }
    },
    resultType(newVal, oldVal) {
      console.log("resultType changed!", oldVal, "->", newVal);
    },
    state(state, oldState) {
      console.log("state changed!", oldState, "->", state);
      // if (state === 2) {
      // this.$nextTick(this.initVideoPlayer);
      // }
    }
  }
};
</script>

<style lang="scss" src="./uni-smart-demo.scss" scoped></style>
